import { Component } from '@angular/core';
import { TwoFactorService } from '../services/two-factor.service';
import { AlertService } from '../alerts/alert.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-two-factor-setup',
  templateUrl: 'setup.component.html'
})

export class SetupComponent {
  public message;
  public qrSvg;
  public secretKey;
  public formModel = {
    code: ''
  };

  constructor(
    public sanitizer: DomSanitizer,
    private twoFactorService: TwoFactorService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.twoFactorService.getTwoFactorSetup()
      .then(data => {
        this.qrSvg = data.qr;
        this.secretKey = data.secret_key;
        this.message = data.message;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }

  submitForm() {
    const data = {
      code: this.formModel.code
    };

    this.twoFactorService.submitTwoFactorSetup(data)
      .then(() => {
        this.twoFactorService.submitTwoFactorSetupComplete()
          .then(() => {
            // Hide any existing errors
            this.alertService.hide();

            // redirect to 2fa complete
            this.router.navigate(['/two-factor/recovery-codes']);
          })
          .catch((msg) => {
            console.log(msg);
            this.alertService.show(msg.error.message, 'danger');
          });

      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }
}
