import { Component } from '@angular/core';
import { TwoFactorService } from '../services/two-factor.service';
import { AlertService } from '../alerts/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-two-factor-challenge',
  templateUrl: 'challenge.component.html'
})

export class ChallengeComponent {
  public mode;
  public code;
  public recovery_code;

  constructor(
    private twoFactorService: TwoFactorService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.twoFactorService.getTwoFactorChallenge()
      .then(data => {
        this.mode = data.mode;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }

  submitForm() {
    const data = {
      mode: this.mode,
      code: this.code,
      recovery_code: this.recovery_code
    };

    this.twoFactorService.submitTwoFactorChallenge(data)
      .then(() => {
        this.router.navigate(['/']);
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }
}
