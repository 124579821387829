import { Component } from '@angular/core';
import { TwoFactorService } from '../services/two-factor.service';
import { AlertService } from '../alerts/alert.service';

@Component({
  selector: 'app-two-factor-recovery-codes',
  templateUrl: 'recovery-codes.component.html'
})

export class RecoveryCodesComponent {
  public recoveryCodes = [];

  constructor(
    private twoFactorService: TwoFactorService,
    private alertService: AlertService
  ) {
    this.twoFactorService.viewRecoveryCodes()
      .then(data => {
        this.recoveryCodes = data.recovery_codes;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }

  regenerateCodes() {
    this.twoFactorService.regenerateRecoveryCodes()
      .then(data => {
        this.recoveryCodes = data.recovery_codes;
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }
}
