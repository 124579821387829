import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class TwoFactorService {
  constructor(private readonly apiService: ApiService) {}

  public getTwoFactorSetup(): Promise<any> {
    return this.apiService.get<any>(
        `api/rumbles/two-factor/setup`
      )
      .toPromise<any>();
  }

  public submitTwoFactorSetup(data): Promise<any> {
    return this.apiService.post<any>(
        `api/rumbles/two-factor/setup`,
        data
      )
      .toPromise<any>();
  }

  public submitTwoFactorSetupComplete(): Promise<any> {
    return this.apiService.get<any>(
        `api/rumbles/two-factor/setup/complete`
      )
      .toPromise<any>();
  }

  public viewRecoveryCodes(): Promise<any> {
    return this.apiService.get<any>(
        `api/rumbles/two-factor/recovery-codes`
      )
      .toPromise<any>();
  }

  public regenerateRecoveryCodes(): Promise<any> {
    return this.apiService.get<any>(
        `api/rumbles/two-factor/recovery-codes/regenerate`
      )
      .toPromise<any>();
  }

  public clearLastChallenged() {
    return this.apiService.get<any>(
        `api/rumbles/two-factor/challenge/clear-last-challenged`
      )
      .toPromise<any>();
  }

  public getTwoFactorChallenge() {
    return this.apiService.get<any>(
        `api/rumbles/two-factor/challenge`
      )
      .toPromise<any>();
  }

  public submitTwoFactorChallenge(data) {
    return this.apiService.post<any>(
        `api/rumbles/two-factor/challenge`,
        data
      )
      .toPromise<any>();
  }

  public getTwoFactorLocked() {
    return this.apiService.get<any>(
        `api/rumbles/two-factor/locked`
      )
      .toPromise<any>();
  }

  public disableTwoFactorAuth() {
    return this.apiService.get<any>(
        `api/rumbles/two-factor/disable`
      )
      .toPromise<any>();
  }
}
