import { Injectable, Inject, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse,
  HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpUserEvent, HttpXsrfTokenExtractor
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs/Rx';
import { switchMap, catchError, finalize } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  unauthorisedCount = 0;
  maxUnauthorisedCount = 3;

  constructor(
    private router: Router,
    public inj: Injector,
    public authService: AuthService,
    private tokenService: HttpXsrfTokenExtractor
  ) { }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    const xsrfToken = this.tokenService.getToken() as string;

    if (xsrfToken !== null && !req.headers.has('X-XSRF-TOKEN')) {
      return req.clone({
        withCredentials: true,
        setHeaders: {
          'Authorization': 'Bearer ' + token,
          'X-XSRF-TOKEN': xsrfToken,
          'X-Requested-With': 'XMLHttpRequest'
        }});
    }

    return req.clone({
      withCredentials: true,
      setHeaders: {
        'Authorization': 'Bearer ' + token,
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<never | HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpEvent<any> | HttpUserEvent<any>> {
    return next.handle(this.addToken(req, this.authService.getToken()))
      .catch(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 400:
              return this.handle400Error(error);
            case 401:
              return this.handle401Error(error, req, next);
            case 403:
              return this.handle403Error(error);
            default:
              return throwError(error);
          }
        } else {
          return throwError(error);
        }
      });
  }

  handle400Error(error: HttpErrorResponse) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      this.authService.logout();
    }

    return throwError(error);
  }

  handle401Error(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) {
    if (
      error.error.error_key === 'invalid_tool_licence_exception' ||
      error.error.error_key === 'no_linked_organisation_exception'
    ) {
      this.authService.logout();
      return throwError(error);
    } else if (error.error.error === 'invalid_credentials') {
      error.error.message = 'Error! The email or password you provided are incorrect. Please try again.';
      return throwError(error);
    } else if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.unauthorisedCount++;

      console.log(this.isRefreshingToken);

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      console.log('About to get new access token...');

      return this.authService.getNewAcessToken()
        .pipe(
          switchMap((newToken: Observable<object>) => {
            console.log('Get new access token request complete');
            if (newToken) {
              const newAccessToken = (newToken as any).access_token;
              this.unauthorisedCount = 0;
              localStorage.setItem('access_token', newAccessToken);
              this.tokenSubject.next(newAccessToken);
              return next.handle(this.addToken(req, newAccessToken));
            }

            // If we don't get a new token, we are in trouble so logout.
            this.authService.logout();
            return throwError('');
          }),
          catchError(err => {
            console.log('Catch error of getting new token');

            if (typeof err.error !== 'undefined' && typeof err.error.message !== 'undefined') {
              this.authService.logout(err.error.message);
            } else {
              this.authService.logout();
            }
            // If there is an exception calling 'refreshToken', bad news so logout.
            return throwError(err);
          }),
          finalize(() => {
            console.log('Completed');
            this.isRefreshingToken = false;
          })
        );
    } else {
      // only hits if refresh token and gets 401
      if (error.status === 401 && error.url.includes('oauth/token')) {
        console.log('Refresh token received 401, logging out.');
        this.authService.logout();
        return throwError({
          message: 'Session has expired, please login again.'
        });
      }

      return this.tokenSubject
        .filter(token => token != null)
        .take(1)
        .switchMap(token => {
          console.log('hit switch map');
          return next.handle(this.addToken(req, token));
        });
    }
  }

  handle403Error(error: HttpErrorResponse) {
    if (error.error.error_type === 'two_factor_auth') {
      console.log('2FA Error', error.error);

      // Redirect to home page if two factor is complete
      if (error.error.error === 'two_factor_complete') {
        this.router.navigate(['/']);
      }

      // If account is locked, redirect there
      if (error.error.error === 'two_factor_locked') {
        this.router.navigate(['/two-factor/locked']);
      }

      // If the two factor setup is required, redirect there
      if (error.error.error === 'two_factor_setup_required') {
        console.log('two factor setup');
        this.router.navigate(['/two-factor/setup']);
      }

      // If user needs to be challenged, do that
      if (error.error.error === 'two_factor_challenge') {
        console.log('two factor challenge');
        this.router.navigate(['/two-factor/challenge']);
      }

      return throwError(error);
    }

    this.router.navigate(['/auth/login']);

    return throwError(error);
  }
}
