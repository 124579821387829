import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoaderService } from '../loader/loader.service';
import { environment } from '../../environments/environment';
import { last, tap } from 'rxjs/internal/operators';

@Injectable()

export class ApiService {
  constructor(
    private readonly http: HttpClient,
    private loaderService: LoaderService
  ) {}

  public get<T>(path: string, options?): Observable<any> {
    const opt = Object.assign({}, options, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });

    return this.request(opt.method, path, opt);
  }

  public post<T>(path: string, data?: any, options?): Observable<any> {
    const opt = Object.assign({}, options, {
      body: data,
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });

    return this.request(opt.method, path, opt);
  }

  public put<T>(path: string, data?: any, options?): Observable<any> {
    const opt = Object.assign({}, options, {
      body: data,
      method: 'PUT',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });

    return this.request(opt.method, path, opt);
  }

  public delete<T>(path: string, data?: any, options?): Observable<any> {
    const opt = Object.assign({}, options, {
      body: data,
      method: 'DELETE',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });

    return this.request(opt.method, path, opt);
  }

  private request(method, path: string, options): Observable<any> {
    this.showLoader();

    const url = `${environment.apiEndpoint}/${path}`;

    return this.http.request(method, url, options)
      .pipe(
        tap(() => {
          this.hideLoader();
        })
        // catchError(this.handleError(file))

        // finally(() => {
        //   this.hideLoader();
        // });
      )
      .catch((error) => {
        this.hideLoader();

        // Rethrow
        return throwError(error);
    });

  }

  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
