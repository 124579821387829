import { Component } from '@angular/core';
import { TwoFactorService } from '../services/two-factor.service';
import { AlertService } from '../alerts/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-two-factor-locked',
  templateUrl: 'locked.component.html'
})

export class LockedComponent {
  public maxAttempts;

  constructor(
    private twoFactorService: TwoFactorService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.twoFactorService.getTwoFactorLocked()
      .then(data => {
        this.maxAttempts = data.maxAttempts;

        if (data.twoFactorLocked === false) {
          this.router.navigate(['/']);
        }
      })
      .catch((msg) => {
        console.log(msg);
        this.alertService.show(msg.error.message, 'danger');
      });
  }
}
