import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { QuestblocksService } from '../services/questblocks.service';

@Component({
  selector: 'app-training-essentials',
  templateUrl: './training-essentials.component.html'
})
export class TrainingEssentialsComponent {
  constructor(
    private questBlocksService: QuestblocksService
  ) {
    // Call, but don't use any data.
    // Will redirect to any 2FA pages if needed.
    this.questBlocksService.getQuestBlocks();
  }
}
